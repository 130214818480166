import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import bg from "../images/background-1.jpg";
import scientist from "../images/scientist-1.jpg";
import scientist2 from "../images/scientist-2.jpg";
import FdaChart from "../images/chat1.png";
import SEO from "../components/seo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#333",
  },
}));

export default function Compare() {
  const classes = useStyles();
  return (
    <>
      <SEO
        title="Lab Notebook App"
        description="LabLog packs more features for less money. LabLog vs LabArchives | LabLog vs SciNote | LabLog vs LabFolder. Pay 75% less for more features and a better experience."
      />

      <Container maxWidth="sm">
        <Typography component="div">
          <Box
            textAlign="center"
            component="h1"
            m={1}
            fontFamily="DM Serif Text"
            fontWeight="700"
            color="#3d3d3d"
            fontSize="h2.fontSize"
          >
            LabLog is the smart alternative
          </Box>
          <br />
          <Box color="#3d3d3d" component="p" textAlign="center">
            LabLog packs more features for less money than the competition
          </Box>
        </Typography>
      </Container>
      <Container maxWidth="lg" style={{ paddingBottom: "50px" }}>
        <Grid container spacing={3} style={{ backgroundColor: "#fff" }}>
          <Grid item md style={{ backgroundColor: "#fff" }}>
            <Typography component="div">
              <Box
                textAlign="left"
                m={1}
                component="h2"
                fontFamily="DM Serif Text"
                fontWeight="700"
                color="#3d3d3d"
                fontSize="h3.fontSize"
              >
                Pay 75% less for more features and a better experience
              </Box>
            </Typography>
            <img
              src={scientist}
              alt="scientists in laboratory"
              style={{ maxWidth: "90%" }}
            />
          </Grid>
          <Grid item md>
            <Typography component="div">
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Get free local and on-site support in United States, vs paying
                thousands of dollars for remote support with others.
                <Box />
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Dedicated mobile apps work offline and sync online, vs Website
                interface online-limited with others.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Integrated analytics feature to easily track task data, and
                monitor progress.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Schedule Tasks and create actionable protocols. Get
                notification on your smart phone.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Send requests for document review and approval.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Speed up FDA applications with an all-in-one solution with FDA
                compliance for electronic records.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Modern and Powerful API allows integration with current
                systems in your laboratory.
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                ✓ Flexible data storage options include in-house data storage,
                vs third-party only with others.
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "30px", paddingBottom: "50px" }}
      >
        <Grid
          container
          spacing={3}
          style={{ marginTop: "30px", backgroundColor: "#fff" }}
        >
          <Grid item md>
            <Typography component="div">
              <Box
                textAlign="left"
                m={1}
                component="h2"
                fontFamily="DM Serif Text"
                fontWeight="700"
                color="#3d3d3d"
                fontSize="h3.fontSize"
              >
                LabLog is easy to use for everyone
              </Box>
              <Box color="#3d3d3d" marginTop="50px" textAlign="left">
                Find the tools you need without sacrificing usability. We packed
                just the right amount of features to make your job easier, but
                not more than that.
              </Box>
            </Typography>
          </Grid>
          <Grid item md style={{ backgroundColor: "#fff" }}>
            <img
              src={scientist2}
              alt="two scientists taking notes in the lab"
              style={{ maxWidth: "90%" }}
            />
          </Grid>
        </Grid>
      </Container>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container
          style={{
            paddingTop: "50px",
            paddingBottom: "30px",
            width: "100%",
          }}
        >
          <Grid container spacing={3}>
            <Grid item md>
              <Typography component="div">
                <Box
                  textAlign="left"
                  m={1}
                  component="h2"
                  fontFamily="DM Serif Text"
                  fontWeight="700"
                  color="#3d3d3d"
                  fontSize="h3.fontSize"
                >
                  One tool for everything
                </Box>
                <Box color="#3d3d3d" marginTop="10px" textAlign="left">
                  Use LabLog to plan tasks, manage data, track results, stay FDA
                  compliant, and much more.
                </Box>
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Box color="#3d3d3d" marginTop="80px" textAlign="left">
                <i>
                  &quote;We chose LabLog because I had heard about the company
                  from our peers, and they were all raving about the great
                  features. We were not disappointed at all! &quote;
                  <br />
                  <br />- Adam Azulai, National Institutes of Health
                </i>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item md={4}>
              <Typography component="div">
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ Local support
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Based in Maryland we can provide local support at your
                  location in United States.
                </Box>
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ Data storage
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Flexible and secure data storage options includes on-premise
                  and cloud options.
                </Box>
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ Analytics
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Gain meaningful insights with your data recorded with LabLog
                  labnotebook app.
                </Box>
                <Box
                  marginBottom="0"
                  textAlign="left"
                  component="h5"
                  fontFamily="DM Serif Text"
                  fontWeight="600"
                  color="#3d3d3d"
                  fontSize="h5.fontSize"
                >
                  ➢ FDA Compliance
                </Box>
                <Box color="#3d3d3d" component="p" textAlign="left">
                  Stay compliant while saving time and money with electronic
                  records.
                </Box>
              </Typography>
            </Grid>
            <Grid item md>
              <img
                alt="fda chart"
                src={FdaChart}
                style={{ maxWidth: "95%", alignSelf: "right" }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
